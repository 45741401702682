<template>
	<div class="child_content">
		<div class="left-box">
			<div class="box-title">类型管理</div>
			<div class="tiem" :class="{'active':tab==1}" @click="on_tab(1)">添加类型</div>
			<div class="tiem" :class="{'active':(tab==0 || !tab)}" @click="on_tab(0)">类型列表</div>
		</div>
		<div class="right-box">
			<add-div class="right-div" v-if="tab==1" @taplist="taplist"></add-div>
			<list-div class="right-div" v-if="tab==0" @edit="edit"></list-div>
			<edit-div class="right-div" v-if="tab==2" :id="eid"></edit-div>
		</div>
	</div>
</template>

<script>
	import AddDiv from './add.vue';
	import ListDiv from './list.vue';
	import editDiv from './edit.vue';
	export default {
		components: {
			AddDiv,
			ListDiv,
			editDiv
		},
		data() {
			return {
				tab: 0,
				eid: 0,
			}
		},

		methods: {
			on_tab(inx) {
				this.tab = inx
			},
			edit(e) {
				this.tab = 2;
				let id = e.id;
				this.eid = id;
			},
			taplist(e) {
				console.log(e);
				this.tab = 0;
			}
		}
	}
</script>

<style>
	@import './type.css';
</style>
