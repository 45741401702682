<template>
	<div class="list-box">
		<div class="list-container">
			<div class="list-main">
				<!-- <fieldset class="table-search-fieldset">
					<legend>搜索信息</legend>
					<div class="search">
						<div class="form-item">
							<div class="item-inline">
								<label class="form-label form-xlabel">用户姓名</label>
								<div class="input-inline">
									<input type="text" name="username" autocomplete="off" class="input" />
								</div>
							</div>
							<div class="item-inline">
								<label class="form-label form-xlabel">用户性别</label>
								<div class="input-inline">
									<input type="text" name="username" autocomplete="off" class="input" />
								</div>
							</div>
							<div class="item-inline">
								<label class="form-label form-xlabel">用户城市</label>
								<div class="input-inline">
									<input type="text" name="username" autocomplete="off" class="input" />
								</div>
							</div>
							<div class="item-inline">
								<label class="form-label form-xlabel">用户职业</label>
								<div class="input-inline">
									<input type="text" name="username" autocomplete="off" class="input" />
								</div>
							</div>
							<div class="item-inline">
								<button type="submit" class="btn btn-primary">
									<i class="iconfont icon-sousuo"></i> 搜 索
								</button>
							</div>
						</div>
					</div>
				</fieldset> -->
				<div class="table-box">
					<!-- <div class="table-tool">
						<button class="form-btn btn-sm btn-normal" type="button">
							添加
						</button>
						<button class="form-btn btn-sm btn-danger" type="button">
							删除
						</button>
					</div> -->
					<div class="table">
						<el-table :data="tableData" style="width: 100%" row-key="id" border default-expand-all
							:tree-props="{ children: 'children' }">
							<el-table-column prop="title" label="名称" width="180">
							</el-table-column>
							<el-table-column prop="img_src" width="180" label="图标">
								<template slot-scope="scope">
									<img v-if="scope.row.img_src" :src="scope.row.img_src" width="50" height="50"
										class="head_pic" />
								</template>
							</el-table-column>
							<el-table-column prop="create_time" label="添加时间">
							</el-table-column>
							<el-table-column fixed="right" label="操作" width="180">
								<template slot-scope="scope">
									<el-button size="mini" @click.native.prevent="editRow(scope.row.id)">修改</el-button>
									<el-button size="mini" v-if="scope.row.children.length == 0" type="danger"
										@click.native.prevent="deleteRow(scope.row.id)">移除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from "../../common/axios";
	export default {
		data() {
			return {
				tableData: [],
			};
		},
		mounted() {
			this.get_list();
		},
		methods: {
			get_list() {
				let type_list = [];
				let that = this;
				let loading = this.$loading({
					text: "Loading",
				});
				let url = '/adminshop/type/list.html';
				axios.post(url).then((res) => {
					loading.close();
					let success = res.success;
					let data = res.data;
					if (success) {
						let list = data.list;
						if (list != undefined && list != null && list != "") {
							type_list = list;
						}
					} else {
						console.log(res.error);
					}
					that.tableData = type_list;
				});
			},
			editRow(id) {
				this.$emit("edit", {
					id: id
				});
			},
			deleteRow(id) {
				this.$confirm('此操作将永久删除这列数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {

					this.post_delrow(id);
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			post_delrow(id) {
				let loading = this.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				let url = '/adminshop/type/del_type.html';
				let data = {id}
				axios.post(url,data).then((res) => {
					loading.close();
					let success = res.success;
					let data = res.data;
					if (success) {
						// console.log(data);
						if (data.status == 1) {
							this.$message({
								type: 'success',
								message: data.msg
							});
							this.get_list();
						} else {
							this.$message.error(data.msg);
						}
					} else {
						this.$message.error("操作失败");
					}
				});
			}
		},
	};
</script>

<style>
	@import "../../static/css/common/index.css";
	@import "../../static/css/common/list.css";
</style>
