<template>
	<div class="add_content">
		<div class="div-kong"></div>
		<div class="com-box">
			<div class="head_title">添加类型</div>
			<div class="form-item">
				<label class="form-label">语言</label>
				<div class="input-inline">
					<cus-select :list="language_list" :must="true" :value="language_id"
						@change="on_selchange($event,'language_id')"></cus-select>
				</div>
			</div>
			<div class="form-item">
				<label class="form-label">类型名称</label>
				<div class="input-inline">
					<cus-input class="input" :max_num="30" @chenginput="cheng_input($event,'type_name')"></cus-input>
				</div>
			</div>
			<div class="form-item">
				<label class="form-label">类型图标</label>
				<div class="input-block">
					<div class="input-img">
						<cus-upload class="img_upload" :accept="'image/png,image/gif,image/jpg,image/jpeg'"
							:src="img_src" @change="cha_img($event, 'img_src')"></cus-upload>
					</div>
				</div>
			</div>
			<div class="form-item">
				<div class="input-block">
					<button class="input-btn btn-normal" type="button" @click="on_sub">确认保存</button>
				</div>
			</div>
		</div>
		<div class="div-kong"></div>
	</div>
</template>

<script>
	import cusInput from '../from/cus-input.vue'
	import cusSelect from '../from/cus-select.vue'
	import axios from '../../common/axios';
	import CusUpload from "../from/cus-upload.vue";
	export default {
		components: {
			cusInput,
			cusSelect,
			CusUpload
		},
		data() {
			return {
				type_name: "",
				type_id: 0,
				language_list: [{
						id: 0,
						text: "中文"
					},
					{
						id: 1,
						text: "英文"
					},
					{
						id: 2,
						text: "马来西亚语言"
					},
					{
						id: 3,
						text: "泰语"
					},
				],
				language_id: 0,
				img_src: "",
			}
		},
		methods: {
			cheng_input(e, name) {
				// console.log(e,name);
				this[name] = e.value;
			},
			on_selchange(e, name) {
				let value = e.value;
				this[name] = value;
				console.log(e);
			},
			cha_img(e, index) {
				// console.log(e,index);
				this[index] = e.value;
			},

			on_sub() {
				let language_id = parseInt(this.language_id);
				let type_id = this.type_id;
				let type_name = this.type_name;
				let img_src = this.img_src;
				let loading = this.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				let url = '/adminshop/type/xaddtype.html';
				let data={
					type_id,
					type_name,
					img_src,
					language: language_id,
				}
				axios.post(url,data).then((res) => {
					let success = res.success;
					let data = res.data;
					loading.close();
					if (success) {
						let status = data.status;
						if (status == 1) {
							this.$message({
								message: data.msg,
								type: 'success'
							});
							setTimeout(() => {
								// this.$router.go(0);
								this.$emit('taplist', {
									id: 0
								});
							}, 1500)
						} else {
							this.$message.error(data.msg);
						}
					} else {
						this.$message.error('操作错误');
					}
				});
			}
		}
	}
</script>

<style>
	@import '../../static/css/common/index.css';
</style>
